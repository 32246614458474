import '/src/css/index/index.css'
import '/src/css/index/index_bigad.css'
import '/src/js/index/index.js'
import '/src/js/index/pop_select.js'
import '/src/js/index/pub_bigad.js'


/*点击查看更多 */
window.showMore = function() {
    $("#textcontain").height("auto");//取消文字容器高度限制
    $("#btncontain").hide();//隐藏查看更多按钮
}

/*轮播图 */
// index：索引， len：长度

var index = 0, len;
// 类似获取一个元素数组
var imgBox = document.getElementsByClassName("img-slide");
len = imgBox.length;
imgBox[index].style.display = 'block';
// 逻辑控制函数
function slideShow() {
    index ++;
    // 防止数组溢出
    if(index >= len) index = 0;
    // 遍历每一个元素
    for(var i=0; i<len; i++) {
        imgBox[i].style.display = 'none';
    }
    // 每次只有一张图片显示
    imgBox[index].style.display = 'block';
}

// 定时器，间隔3s切换图片
setInterval(slideShow, 4000);
